<template>
  <div class="content-offers">
    <modal
      :active="createNewContentOfferActive"
      @dismiss="createNewContentOfferActive = false"
      class="create-content-offer-modal form-control"
    >
      <template v-if="activeContentOffer">
        <template v-if="activeContentOffer.id === null">
          <h2>Create New Content Offer</h2>
        </template>
        <template v-else>
          <h2>Update Content Offer</h2>
        </template>
        <div class="row">
          <div class="form-row">
            <label>Type</label>
            <select v-model="offerType">
              <option value="">Select</option>
              <option value="internal">Internal</option>
              <option value="external">External</option>
            </select>
          </div>
          <div class="form-row">
            <label>Thumbnail</label>
            <input v-model="activeContentOffer.thumbnail" type="text" />
          </div>
        </div>

        <div class="form-row">
          <label>Conditions</label>
          <select v-model="activeContentOffer.conditions" multiple>
            <option value="">Select</option>
            <option
              v-for="(condition, index) in conditions"
              :key="`condition-${index}`"
              :value="condition.id"
            >
              {{ condition.name }}
            </option>
          </select>
        </div>

        <template v-if="offerType === 'external'">
          <div class="row">
            <div class="form-row">
              <label>Title</label>
              <input v-model="activeContentOffer.title" type="text" />
            </div>

            <div class="form-row">
              <label>URL</label>
              <input v-model="activeContentOffer.url" type="text" />
            </div>
          </div>

          <div class="form-row">
            <label>Description</label>
            <textarea v-model="activeContentOffer.description"></textarea>
          </div>
        </template>
        <template v-else-if="offerType === 'internal'">
          <div class="form-row">
            <label>View</label>
            <select v-model="activeContentOffer.view_id">
              <option
                v-for="(view, index) in views"
                :key="`view-${index}`"
                :value="view.id"
              >
                {{ view.campaign.name }} | {{ view.name }}
              </option>
            </select>
          </div>

          <div class="form-row">
            <label>Gated</label>
            <toggle-switch
              :checked="activeContentOffer.gated"
              @on-change="onGatedChange"
            />
          </div>

          <template v-if="activeContentOffer.gated">
            <h2>Fulfillment Email Settings</h2>

            <div class="row">
              <div class="form-row">
                <label>Outbound Title</label>
                <input v-model="activeContentOffer.ob_title" type="text" />
              </div>

              <div class="form-row">
                <label>Outbound Description</label>
                <input
                  v-model="activeContentOffer.ob_description"
                  type="text"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-row">
                <label>Recommended Offer Type</label>
                <select v-model="activeContentOffer.ob_offer_type">
                  <option value="">Select</option>
                  <option value="dynamic">Dynamic</option>
                  <option value="manual">Manual</option>
                </select>
              </div>

              <div
                v-if="activeContentOffer.ob_offer_type === 'manual'"
                class="form-row"
              >
                <label>Recommended Offer View</label>
                <select v-model="activeContentOffer.next_view_id">
                  <option
                    v-for="(view, index) in views"
                    :key="`view-${index}`"
                    :value="view.id"
                  >
                    {{ view.campaign.name }} | {{ view.name }}
                  </option>
                </select>
              </div>
            </div>
          </template>
        </template>

        <input v-model="activeContentOffer.id" type="hidden" />

        <template v-if="activeContentOffer.id === null">
          <button class="button" @click="onCreate">Create</button>
        </template>
        <template v-else>
          <button class="button" @click="onUpdate">Update</button>
        </template>
      </template>
    </modal>

    <loading :active="loading" />

    <div class="hero hero--engage hero--offers">
      <div class="container">
        <h2>Content Offers</h2>
        <div class="filterbar">
          <div class="filterbar__input">
            <input
              type="text"
              v-model="filter"
              v-on:keyup.enter="onFilterOffers"
            />
          </div>
          <div class="filterbar__select">
            <select v-model="condition">
              <option value="">Condition</option>
              <option
                v-for="(condition, index) in conditions"
                :key="`condition-${index}`"
                :value="condition.id"
              >
                {{ condition.name }}
              </option>
            </select>
          </div>
          <div class="filterbar__submit">
            <button @click="onFilterOffers">Filter</button>
          </div>
        </div>

        <button class="button" @click="onNewContentOffer">
          New Content Offer
        </button>
      </div>
    </div>

    <div class="container">
      <div class="offers-wrapper card-wrapper">
        <div class="card form-control">
          <table
            class="
              styled-table styled-table--with-footer styled-table--no-header
            "
          >
            <tr v-for="offer in filteredOffers" :key="`offer-${offer.id}`">
              <td class="thumbnail">
                <div class="thumbnail__wrapper">
                  <BaseImage v-if="offer.thumbnail" :src="offer.thumbnail" />
                </div>
              </td>
              <td class="title">
                <a :href="offer.url" target="_blank">{{ offer.title }}</a>
                <p class="title__description">
                  {{ offer.description | truncate(230) }}
                </p>
              </td>
              <td class="actions">
                <div class="actions__wrapper">
                  <div class="cell">
                    <button @click="onEdit(offer.id)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M18 14.45v6.55h-16v-12h6.743l1.978-2h-10.721v16h20v-10.573l-2 2.023zm1.473-10.615l1.707 1.707-9.281 9.378-2.23.472.512-2.169 9.292-9.388zm-.008-2.835l-11.104 11.216-1.361 5.784 5.898-1.248 11.103-11.218-4.536-4.534z"
                        />
                      </svg>
                      Edit
                    </button>
                  </div>
                  <div class="cell delete">
                    <button @click="onDelete(offer.id, offer.title)">
                      Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <Pagination
            :items="offers"
            @paginate="onPaginate"
            v-show="filteredOffers.length"
          ></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import ContentOffer from "@/models/ContentOffer";
import BaseImage from "@/components/base/BaseImage";
import Pagination from "@/components/Pagination";
import Loading from "@/components/Loading";
import ToggleSwitch from "@/components/ToggleSwitch";

export default {
  name: "ContentOffers",
  components: {
    Modal,
    BaseImage,
    Pagination,
    Loading,
    ToggleSwitch,
  },
  data() {
    return {
      createNewContentOfferActive: false,
      activeContentOffer: null,
      offerType: "internal",
      filter: null,
      condition: "",
      filteredOffers: [],
    };
  },
  computed: {
    loading() {
      return this.$store.getters["contentOffers/loading"];
    },
    offers() {
      return this.$store.getters["contentOffers/offers"];
    },
    views() {
      return this.$store.getters["contentOffers/views"].filter(function (view) {
        return view.template === "article";
      });
    },
    conditions() {
      return this.$store.getters["contentOffers/conditions"];
    },
    offer() {
      return this.$store.getters["contentOffers/offer"];
    },
  },
  methods: {
    onCreate() {
      this.$store
        .dispatch("contentOffers/addOffer", this.activeContentOffer)
        .then(() => {
          this.createNewContentOfferActive = false;
          this.onFilterOffers();
        });
    },
    onUpdate() {
      this.$store
        .dispatch("contentOffers/updateOffer", this.activeContentOffer)
        .then(() => {
          this.createNewContentOfferActive = false;
          this.onFilterOffers();
        });
    },
    onDelete(id, title) {
      if (confirm('Are you sure you want to delete "' + title + '"?')) {
        this.$store
          .dispatch("contentOffers/deleteOffer", { contentOfferId: id })
          .then(() => {
            this.refreshOffers();
          });
      }
    },
    onNewContentOffer() {
      this.activeContentOffer = new ContentOffer();
      this.createNewContentOfferActive = true;
      this.offerType = "internal";
    },
    onEdit(id) {
      this.$store
        .dispatch("contentOffers/getOffer", { contentOfferId: id })
        .then(() => {
          this.activeContentOffer = this.offer;
          this.activeContentOffer.conditions =
            this.activeContentOffer.conditions.map((condition) => {
              return condition.id;
            });
          this.createNewContentOfferActive = true;
          this.offerType =
            this.offer.view_id === null ? "external" : "internal";
        });
    },
    refreshOffers() {
      this.$store.dispatch("contentOffers/getOffers");
    },
    onPaginate(items) {
      this.filteredOffers = items;
    },
    onFilterOffers() {
      this.$store.dispatch("contentOffers/getOffers", {
        filter: this.filter,
        condition: this.condition,
      });
    },
    onGatedChange() {
      this.activeContentOffer.gated = !this.activeContentOffer.gated;
    },
  },
  mounted() {
    if (!this.views.length) {
      this.$store.dispatch("contentOffers/getViews");
    }

    if (!this.conditions.length) {
      this.$store.dispatch("contentOffers/getConditions");
    }

    if (!this.offers.length || !this.filteredOffers.length) {
      this.refreshOffers();
    }
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button";
@import "@/scss/forms";
@import "@/scss/table";
@import "@/scss/hero";
@import "@/scss/filter";

.create-content-offer-modal {
  .toggle-switch {
    margin-bottom: 1.25rem !important;
    .slider {
      background-color: $fade-gray;
    }
    input:checked + .slider {
      background-color: $dark-blue;
    }
  }

  .toggle-switch,
  input[type="checkbox"] {
    margin-right: 8px;
  }

  .modal-content {
    width: 760px;
  }

  .row {
    .form-row:not(:only-child) {
      max-width: 70%;
    }
  }
}

.hero--offers {
  .container {
    justify-content: space-between;

    .button {
      margin-left: 0 !important;
    }
  }
}

.content-offers {
  .form-row {
    flex-grow: 1;
    margin-bottom: 1em;

    label {
      display: block;
      margin-bottom: 6px;
    }

    textarea {
      height: 44px;
    }
  }

  .styled-table {
    td {
      vertical-align: middle;

      &:first-of-type {
        padding-right: 0;
      }
    }

    .title {
      line-height: 1.25;

      a {
        display: block;
        font-weight: 600;
        font-size: 115%;
      }

      &__description {
        color: $medium-gray;
      }
    }

    .tags {
      font-weight: 600;
      text-transform: uppercase;
      width: 175px;
    }

    .thumbnail {
      width: 150px;

      &__wrapper {
        width: 100%;
        padding-bottom: 75%;
        position: relative;
        background-color: $fade-gray;
        border-radius: 4px;
        overflow: hidden;
      }
    }

    .image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &__item {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .actions {
      width: 225px;

      &__wrapper {
        display: flex;
        justify-content: space-between;
      }
    }

    .cell {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 0.875rem;

      &.delete {
        button {
          color: $red;
        }
      }

      svg {
        width: 14px;
        height: 14px;
        fill: $green;
        margin-top: -2px;
        margin-right: 0.5rem;
      }
    }

    button {
      background-color: $fade-gray;
      font-family: inherit;
      box-shadow: 0 0 4px rgba($color: #000, $alpha: 0);
      border: 0;
      cursor: pointer;
      height: 32px;
      display: inline-flex;
      align-items: center;
      padding: 0 0.75rem;
      transition: 0.25s all ease;
      color: $body-gray;

      &:hover {
        box-shadow: 0 0 4px rgba($color: #000, $alpha: 0.15);
      }
    }
  }

  .offers-wrapper {
    margin: 0 -1rem;
  }
}
</style>