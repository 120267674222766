function ContentOffer() {
  return {
    id: null,
    title: null,
    url: null,
    thumbnail: null,
    description: null,
    read_minutes: 1,
    view_id: null,
    conditions: [],
    gated: false,
    ob_title: null,
    ob_description: null,
    ob_offer_type: null,
    next_view_id: null,
  };
}

export default ContentOffer;
